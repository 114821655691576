<template>
  <div class="page">
    <validation-observer
      ref="registerForm"
      #default="{invalid}"
    >
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <b-card title="User Information">
            <b-form-group
              label="Profile Image"
              label-for="account-profile-pic"
            >
              <b-avatar
                size="6rem"
                variant="light-primary"
                :src="user.profile"
                class="accoutProfilePic d-flex mx-auto my-2"
              />
              <b-form-file
                ref="accountProfilePicRef"
                v-model="profileImage"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                accept="image/*"
                @change="onSelectProfilcePic"
              />
              <!-- v-model="account.profilePic" -->
            </b-form-group>
            <b-form-group
              label="Name"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="name"
                    v-model="user.name"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="name"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="MLS ID"
              label-for="mls-id"
            >
              <validation-provider
                #default="{ errors }"
                name="MLS ID"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="mls-id"
                    v-model="user.mlsId"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="mls-id"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Slug"
              label-for="slug"
            >
              <validation-provider
                #default="{ errors }"
                name="Slug"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="slug"
                    v-model="user.slug"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="slug"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Role"
              label-for="role"
            >
              <validation-provider
                #default="{ errors }"
                name="Role"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="role"
                    v-model="user.role"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="role"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="email"
                    v-model="user.email"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="email"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Phone"
              label-for="phone"
            >
              <validation-provider
                #default="{ errors }"
                name="Phone"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="phone"
                    v-model="user.phone"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="phone"
                    @input="formatPhoneNumber(user.phone)"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Website"
              label-for="website"
            >
              <validation-provider
                #default="{ errors }"
                name="Website"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="website"
                    v-model="user.website"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="website"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-alert
              v-if="errorMsg"
              show
              variant="danger"
            >
              <div class="alert-body">
                {{ errorMsg }}
              </div>
            </b-alert>
            <b-button
              variant="outline-primary"
              class="mb-2"
              :disabled="invalid"
              @click="save"
            >
              Save
            </b-button>
          </b-card>
        </div>
        <div class="col-col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <b-card>
            <b-form-group
              label-for="description"
              label="Description"
            >
              <b-form-textarea
                id="description"
                v-model="user.remark"
                rows="15"
              />
            </b-form-group>
          </b-card>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BFormGroup, BButton, BInputGroup, BFormInput, BAlert, BFormTextarea, BAvatar, BFormFile,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'

export default {
  components: {
    BCard, BButton, ValidationProvider, ValidationObserver, BFormGroup, BInputGroup, BFormInput, BAlert, BFormTextarea, BAvatar, BFormFile,
  },
  data() {
    return {
      errorMsg: '',
      required,
      roleOptions: [
        { value: 'agent', text: 'Agent' },
        { value: 'manager', text: 'Mananger' },
        { value: 'assistant', text: 'Assistant' },
      ],
      officeOptions: [],
      user: {
        name: '',
        role: 'Realtor',
        mlsId: '',
        email: '',
        phone: '',
        office: null,
        website: '',
        remark: '',
        slug: '',
        profile: '',
      },
      profileImage: null,
    }
  },
  computed: {
    getMaxFileSize() {
      return this.$store.state.MAX_FILE_UPLOAD_SIZE
    },
  },
  methods: {
    formatPhoneNumber(data) {
      const cleaned = ('' + data).replace(/\D/g, '')
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        this.user.phone = match[1] + '.' + match[2] + '.' + match[3]
      }
    },
    save() {
      const formData = new FormData()

      const data = {
        id: this.user.id,
        name: this.user.name,
        slug: this.user.slug,
        role: this.user.role,
        phone: this.user.phone,
        email: this.user.email,
        agent_mls_loginName: this.user.mlsId,
        website: this.user.website,
        remark: this.user.remark,
        profile: this.user.profile,
      }

      Object.keys(data).forEach(key => {
        if (key !== 'profile') {
          formData.append(key, data[key])
        }
      })

      formData.append('profileImage', this.profileImage)

      this.$store.dispatch('offices/addAgent', formData).then(response => {
        if (response.code === 200) {
          this.$router.push({ name: 'brokerage' })
        } else {
          this.errorMsg = response.message
        }
      }).catch(err => {
        console.error(err)
      })
    },
    onSelectProfilcePic(e) {
      const image = e.target.files[0]

      if (image.size < 1024 * this.getMaxFileSize) {
        const reader = new FileReader()

        reader.readAsDataURL(image)

        reader.onload = event => {
          this.user.profile = event.target.result
          this.profileImage = image
        }
      } else {
        this.$refs.accountProfilePicRef.reset()
        // this.previewProfilePic = this.originProfilePic
        this.showToast('Upload Failed', 'AlertCircleIcon', 'File exceeds the maximum file size (1MB)', 'danger', 'bottom-right')
      }
    },
    showToast(title, icon, text, variant, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        position,
      })
    },
  },
}
</script>

<style lang="scss">
</style>
